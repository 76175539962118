export const BACKGROUNDCOLORS = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 99, 71, 0.2)',
    'rgba(255, 165, 0, 0.2)',
    'rgba(34, 139, 34, 0.2)',
    'rgba(106, 90, 205, 0.2)',
    'rgba(255, 20, 147, 0.2)',
    'rgba(70, 130, 180, 0.2)',
];

export const BORDERCOLORS = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 71, 1)',
    'rgba(255, 165, 0, 1)',
    'rgba(34, 139, 34, 1)',
    'rgba(106, 90, 205, 1)',
    'rgba(255, 20, 147, 1)',
    'rgba(70, 130, 180, 1)',
];