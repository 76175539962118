import React, { useRef, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useSurveyStore } from '../../store/surveyStore';
import { BACKGROUNDCOLORS } from '../../lib/colors';
import { BORDERCOLORS } from '../../lib/colors';

ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * PieChart component renders a pie chart using the React-ChartJS-2 library.
 * It takes in the following props:
 * - responsesLabels: An array of strings representing the labels for the pie chart slices.
 * - responsesData: An array of numbers representing the data for the pie chart slices.
 * - setterFunctionName: A string representing the name of the function in the store to update with the chart image.
 *
 * The component uses the useEffect hook to delay the conversion of the pie chart to a base64 image and update the store function with the image data after 5 seconds.
 *
 * @param {Array<string>} responsesLabels - An array of strings representing the labels for the pie chart slices.
 * @param {Array<number>} responsesData - An array of numbers representing the data for the pie chart slices.
 * @param {string} setterFunctionName - A string representing the name of the function in the store to update with the chart image.
 *
 * @returns {JSX.Element} - A pie chart rendered using the React-ChartJS-2 library.
 */
function PieChart ({responsesLabels, responsesData, setterFunctionName}) {
    const setterFunction = useSurveyStore(state => state[setterFunctionName]);
    const ref = useRef();

    useEffect(() => {
      setTimeout(() => {
        const chartImage = ref.current.toBase64Image('image/png', 1);
        setterFunction(chartImage);
      }, 5000);
    }, [ref, setterFunction]);

    const data = {
        labels: responsesLabels,
        datasets: [
          {
            data: responsesData,
            backgroundColor: BACKGROUNDCOLORS,
          borderColor: BORDERCOLORS,
            borderWidth: 1,
          },
        ],
    };

    return <Pie ref={ref} data={data} />;
}

export default PieChart;