import { useSurveyStore } from '../store/surveyStore';
import SurveyComponent from './SurveyComponent';
import ResultComponent from './ResultComponent';

/**
 * Main component that renders either the SurveyComponent or ResultComponent based on whether the survey is completed.
 *
 * @returns {JSX.Element} The JSX element representing the main component.
 */
function Main() {
    /**
     * A hook that provides access to the survey store's state.
     *
     * @returns {Object} The survey store's state, including the `isSurveyCompleted` property.
     */
    const { isSurveyCompleted } = useSurveyStore(state => state);

    /**
     * Renders the main component based on whether the survey is completed.
     *
     * @returns {JSX.Element} The JSX element representing the main component.
     */
    return (
        <main className='flex-grow container z-10 mx-auto lg:mx-auto lg:px-12 mt-[229px] md:mt-[161px]'>
            {
                isSurveyCompleted ? 
                    <ResultComponent /> : 
                    <SurveyComponent />
            }
        </main>
    );
}

export default Main;