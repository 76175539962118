export const QuestionsJson = [
    {
        "type": "dropdown",
        "name": "country",
        "title": "Select the country of where your organizations belongs",
        "choices": [
            {
                "value": "Croatia",
                "text": "Croatia"
            },
            {
                "value": "Italy",
                "text": "Italy"
            },
            {
                "value": "Poland",
                "text": "Poland"
            },
            {
                "value": "Slovakia",
                "text": "Slovakia"
            },
            {
                "value": "Slovenia",
                "text": "Slovenia"
            },
            {
                "value": "Czech_Republic",
                "text": "Czech Republic"
            },
        ],
    },
    {
        "type": "dropdown",
        "name": "group",
        "title": "What group does your organization belongs",
        "choices": [
            {
                "value": "Item 1",
                "text": "Circular Producer"
            },
            {
                "value": "Item 2",
                "text": "Circular User"
            },
            {
                "value": "Item 3",
                "text": "Circular Re-user"
            },
            {
                "value": "Item 4",
                "text": "Public Administration"
            }
        ],
    },
    {
        "type": "text",
        "name": "organization_name",
        "title": "What is the name of your organization?",
    },
    {
        "type": "checkbox",
        "name": "specific_1",
        "title": "What are the most commonly collected types of WEEE in the studied area?",
        "choices": [
         {
          "value": "Item 1",
          "text": "Large household appliance"
         },
         {
          "value": "Item 2",
          "text": "Small household appliance"
         },
         {
          "value": "Item 3",
          "text": "IT and telecommunications equipments"
         },
         {
          "value": "Item 4",
          "text": "Consumer Equipment"
         },
         {
          "value": "Item 5",
          "text": "Lighting equipment"
         },
         {
          "value": "Item 6",
          "text": "Electrical and electronic tools"
         },
         {
          "value": "Item 7",
          "text": "Toys, leisure and sports equipment"
         },
         {
          "value": "Item 8",
          "text": "Medical devices"
         },
         {
          "value": "Item 9",
          "text": "Monitoring and control instruments"
         },
         {
          "value": "Item 10",
          "text": "Automatic dispensers"
         }
        ]
    },
    {
        "type": "matrixdropdown",
        "name": "specific_2",
        "title": "Indicate, the percentage, of the amount (weight) of the previous items collected. Please note that the total must be 100%.",
        "rows": [
         {
          "value": "item1",
          "text": "Large household appliance",
         },
         {
          "value": "item2",
          "text": "Small household appliance",
         },
         {
          "value": "item3",
          "text": "IT and telecommunications equipments",
         },
         {
          "value": "item4",
          "text": "Consumer Equipment",
         },
         {
          "value": "item5",
          "text": "Lighting equipment",
         },
         {
          "value": "item6",
          "text": "Electrical and electronic tools",
         },
         {
          "value": "item7",
          "text": "Toys, leisure and sports equipment",
         },
         {
          "value": "item8",
          "text": "Medical devices",
         },
         {
          "value": "item9",
          "text": "Monitoring and control instruments",
         },
         {
          "value": "item10",
          "text": "Automatic dispensers",
         }
        ]
    },
    {
        "type": "matrixdropdown",
        "name": "specific_3",
        "title": "What is the material that constitutes the highest percentage by weight in the collected WEEE in your area?",
        "rows": [
         {
          "value": "item1",
          "text": "Metals"
         },
         {
          "value": "item2",
          "text": "Plastic"
         },
         {
          "value": "item3",
          "text": "Glass and ceramics"
         },
         {
          "value": "item4",
          "text": "Other materials (lithium, manganese, nickel etc)"
         }
        ]
    },
    {
        "type": "comment",
        "name": "specific_4",
        "title": "In your area, and based on your perspective, what specific challenges do rural areas face in terms of WEEE management compared to urban areas?",
    },
    {
        "type": "comment",
        "name": "specific_5",
        "title": "In your area, and based on your perspective, which electronic devices do you estimate are currently unrecovered and stored in homes, contributing to the WEEE problem?",
    },
    {
        "type": "matrixdropdown",
        "name": "specific_6",
        "title": "In your area, and based on your perspective, what is the correlation between public awareness campaigns and WEEE collecting?",
        "columns": [
            {
             "name": "Value",
             "title": "Correlation"
            }
        ],
        "choices": [
            "High",
            "Very high",
            "Low",
            "Very low"
        ],
        "rows": [
            {
             "value": "Row 1",
             "text": "Public awareness campaigns"
            },
            {
             "value": "Row 2",
             "text": "WEEE collecting"
            }
        ]
    },
    {
        "type": "comment",
        "name": "specific_7",
        "title": "In your area, and based on your perspective, describe how the WEEE collection points are distributed in your area.",
    },
    {
        "type": "text",
        "name": "specific_9",
        "title": "In your area, and based on your perspective, describe the problems or positive points regarding the current economic incentives for WEEE collecting programs.",
    },
    {
        "type": "radiogroup",
        "name": "specific_10",
        "title": "At what level does the action plan primarily operate to manage WEEE.",
        "choices": [
         {
          "value": "Item 1",
          "text": "National"
         },
         {
          "value": "Item 2",
          "text": "Regional"
         },
         {
          "value": "Item 3",
          "text": "Municipality"
         }
        ]
    },
    {
        "type": "comment",
        "name": "specific_11",
        "title": "Describes the geography of the area.",
    },
    {
        "type": "comment",
        "name": "specific_12",
        "title": "What is the current situation regarding the problematic in the area, and what challenges need to be overcome in this territory?",
    },
    {
        "type": "boolean",
        "name": "specific_13",
        "title": "Is there any specific policy instrument related to WEEE management in your area?",
    },
    {
        "type": "comment",
        "name": "specific_13_comment",
        "title": "Please elaborate more about the previous question.",
    },
    {
        "type": "comment",
        "name": "specific_14",
        "title": "Please describe the main features of this policy instrument (e.g. objective, characteristics, priority or measure addressed).",
    },
    {
        "type": "comment",
        "name": "specific_15",
        "title": "What kind of improvements do you think that can be implemented for this instrument?",
    },
    {
        "type": "checkbox",
        "name": "specific_16",
        "title": "What are the different methods you think that are used for WEEE recycling in your area?",
        "choices": [
         {
          "value": "item1",
          "text": "Manual Dismantling"
         },
         {
          "value": "item2",
          "text": "Mechanical Processing (Shredding, Magnetic Separation, Density Separation etc)"
         },
         {
          "value": "item3",
          "text": "Chemical Processing (Leaching, Electrochemical Processes etc)"
         },
         {
          "value": "item4",
          "text": "Thermal Processing (Incineration, Pyrolysis etc)"
         },
         {
          "value": "item5",
          "text": "Biological Processing (Utilizes bacteria or fungi to extract metals from e-waste)"
         },
         {
          "value": "item6",
          "text": "Hydrometallurgical Processes (using organic solvents to separate specific metals)"
         },
         {
          "value": "item7",
          "text": "Advanced Sorting Technologies (Uses sensors, cameras, X-RAY to identify and separate materials based on their composition and properties)"
         },
         {
          "value": "item8",
          "text": "Recycling of Specific Components (as batteries)"
         },
         {
          "value": "item9",
          "text": "Re-use and Refurbishment (repairing, upgrading or re use the devices)"
         }
        ]
    },
    {
        "type": "matrixdropdown",
        "name": "specific_17",
        "title": "Indicate the percentage of the previous selected methods used for WEEE recycling in your area. Please note that the total must be 100%.",
        "rows": [
         {
          "value": "item1",
          "text": "Manual Dismantling"
         },
         {
          "value": "item2",
          "text": "Mechanical Processing (Shredding, Magnetic Separation, Density Separation etc)"
         },
         {
          "value": "item3",
          "text": "Chemical Processing (Leaching, Electrochemical Processes etc)"
         },
         {
          "value": "item4",
          "text": "Thermal Processing (Incineration, Pyrolysis etc)"
         },
         {
          "value": "item5",
          "text": "Biological Processing (Utilizes bacteria or fungi to extract metals from e-waste)"
         },
         {
          "value": "item6",
          "text": "Hydrometallurgical Processes (using organic solvents to separate specific metals)"
         },
         {
          "value": "item7",
          "text": "Advanced Sorting Technologies (Uses sensors, cameras, X-RAY to identify and separate materials based on their composition and properties)"
         },
         {
          "value": "item8",
          "text": "Recycling of Specific Components (as batteries)"
         },
         {
          "value": "item9",
          "text": "Re-use and Refurbishment (repairing, upgrading or re use the devices)"
         }
        ]
    },
    {
        "type": "comment",
        "name": "specific_18",
        "title": "Please describe the legal Framework in Chequia.",
    },
    {
        "type": "dropdown",
        "name": "specific_19",
        "title": "As a user, how many outdated devices do you believe you have in your home that should be collected?",
        "choices": [
         {
          "value": "Item 1",
          "text": "Very High (>15)"
         },
         {
          "value": "Item 2",
          "text": "High (>10)"
         },
         {
          "value": "Item 3",
          "text": "Medium (Around 5)"
         },
         {
          "value": "Item 4",
          "text": "Low (Around 2)"
         },
         {
          "value": "Item 5",
          "text": "Very Low (1 or None) "
         }
        ]
    },
    {
        "type": "dropdown",
        "name": "specific_20",
        "title": "In your area, and based on your perspective, do you consider that there are enough WEEE collection points across the studied area? Please select the intensity.",
        "choices": [
         {
          "value": "Item 1",
          "text": "Very sufficient"
         },
         {
          "value": "Item 2",
          "text": "Sufficient"
         },
         {
          "value": "Item 3",
          "text": "Insufficient"
         },
         {
          "value": "Item 4",
          "text": "Very insufficient"
         }
        ]
    },
    {
        "type": "dropdown",
        "name": "specific_21",
        "title": "How significant is the issue of illegal disposal of WEEE in your area, and what measures are being taken to combat it? Please select the significance level.",
        "choices": [
         {
          "value": "Item 1",
          "text": "Very significant"
         },
         {
          "value": "Item 2",
          "text": "Significant"
         },
         {
          "value": "Item 3",
          "text": "Insignificant"
         },
         {
          "value": "Item 4",
          "text": "Very insignificant"
         }
        ]
    },
    {
        "type": "dropdown",
        "name": "specific_22",
        "title": "Do you consider that the economic incentives provided to encourage businesses and individuals to participate in WEEE collecting programs in your area are sufficient?",
        "choices": [
         {
          "value": "Item 1",
          "text": "Very sufficient"
         },
         {
          "value": "Item 2",
          "text": "Sufficient"
         },
         {
          "value": "Item 3",
          "text": "Insufficient"
         },
         {
          "value": "Item 4",
          "text": "Very insufficient"
         }
        ]
    },
    {
        "type": "checkbox",
        "name": "producer_question1",
        "title": "Do you implement:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Environmental management"
            },
            {
             "value": "Item 2",
             "text": "Health and safety"
            },
            {
             "value": "Item 3",
             "text": "Quality management"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "producer_question2",
        "title": "Do you comply to current legislation and best practices for managing hazardous components and waste, which includes:",
        "choices": [
            {
            "value": "Item 1",
            "text": "WEEE Directive"
            },
            {
            "value": "Item 2",
            "text": "Batteries and Accumulators Directive"
            },
            {
            "value": "Item 3",
            "text": "RoHS Directive"
            },
            {
            "value": "Item 4",
            "text": "Eco-design Directive"
            },
            {
            "value": "Item 5",
            "text": "REACH Regulation"
            },
            {
            "value": "Item 6",
            "text": "Basel Convention"
            },
            {
            "value": "Item 7",
            "text": "Greenhouse gas regulations"
            },
            {
            "value": "Item 8",
            "text": "CEN and CENELEC"
            },
            {
            "value": "Item 9",
            "text": "WEEE Label"
            },
            {
            "value": "Item 10",
            "text": "Energy label"
            },
            {
            "value": "none",
            "text": "None"
            }
        ],
    },
    {
        "type": "boolean",
        "name": "producer_question3",
        "title": "Are you in compliance to government regulations concerning health and safety, trading standards, employment, and environmental management?",
    },
    {
        "type": "comment",
        "name": "producer_question3_comment",
        "title": "Please give details:",
    },
    {
        "type": "boolean",
        "name": "producer_question4",
        "title": "Do you have a training programme for all staff?",
    },
    {
        "type": "comment",
        "name": "producer_question4_comment",
        "title": "Please give details:",
    },
    {
        "type": "boolean",
        "name": "producer_question5",
        "title": "Have you carried out a health and safety risk assessment?",
    },
    {
        "type": "comment",
        "name": "producer_question5_comment",
        "title": "Please give details:",
    },
    {
        "type": "checkbox",
        "name": "producer_question6",
        "title": "Have you included the following criteria in your product life cycle and design?",
        "choices": [
            {
            "value": "Item 1",
            "text": "Clean materials"
            },
            {
            "value": "Item 2",
            "text": "Renewable materials"
            },
            {
            "value": "Item 3",
            "text": "Low energy content materials"
            },
            {
            "value": "item1",
            "text": "Recycled materials"
            },
            {
            "value": "item2",
            "text": "Recyclable materials"
            },
            {
            "value": "item3",
            "text": "Alternative production techniques"
            },
            {
            "value": "item4",
            "text": "Fewer production steps"
            },
            {
            "value": "item5",
            "text": "Low/clean energy consumption"
            },
            {
            "value": "item6",
            "text": "Less production waste"
            },
            {
            "value": "item7",
            "text": "Few/clean production consumables"
            },
            {
            "value": "item8",
            "text": "Less/clean/reusable packaging"
            },
            {
            "value": "item9",
            "text": "Energy-efficient transport mode"
            },
            {
            "value": "item10",
            "text": "Energy-efficient logistics"
            },
            {
            "value": "item11",
            "text": "Low energy consumption"
            },
            {
            "value": "item12",
            "text": "Clean energy source"
            },
            {
            "value": "item13",
            "text": "Few consumables"
            },
            {
            "value": "item14",
            "text": "Clean consumables"
            },
            {
            "value": "item15",
            "text": "No wastage of energy or consumables"
            },
            {
            "value": "item16",
            "text": "Reliability and durability"
            },
            {
            "value": "item17",
            "text": "Easy maintenance and repair"
            },
            {
            "value": "item18",
            "text": "Modular product structure"
            },
            {
            "value": "item19",
            "text": "Classic Design"
            },
            {
            "value": "item20",
            "text": "Strong product-user relation"
            },
            {
            "value": "item21",
            "text": "Reuse of product (components)"
            },
            {
            "value": "item22",
            "text": "Remanufacturing/refurbishing"
            },
            {
            "value": "item23",
            "text": "Recycling of materials"
            },
            {
            "value": "item24",
            "text": "Safe incineration"
            },
            {
            "value": "none",
            "text": "None"
            }
        ],
    },
    {
        "type": "boolean",
        "name": "producer_question7",
        "title": "Do you control the release of hazardous substances from any equipment you handle?",
    },
    {
        "type": "comment",
        "name": "producer_question7_comment",
        "title": "Please give details:",
    },
    {
        "type": "boolean",
        "name": "producer_question8",
        "title": "Are there established protocols in place for the management of hazardous compounds that are removed throughout the recycling procedure?",
    },
    {
        "type": "comment",
        "name": "producer_question8_comment",
        "title": "Please give details:",
    },
    {
        "type": "checkbox",
        "name": "producer_question9",
        "title": "Do you comply to current legislation and best practices while managing hazardous waste, including:",
        "choices": [
            {
             "value": "Item 1",
             "text": "WEEE Directive"
            },
            {
             "value": "Item 2",
             "text": "Batteries and Accumulators Directive"
            },
            {
             "value": "Item 3",
             "text": "REACH Regulation"
            },
            {
             "value": "Item 4",
             "text": "POPs regulation"
            },
            {
             "value": "Item 5",
             "text": "Basel Convention"
            },
            {
             "value": "Item 6",
             "text": "Greenhouse gas regulations"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "boolean",
        "name": "producer_question10",
        "title": "Is the handling of hazardous substances exclusively carried out by appropriately competent and qualified personnel?",
    },
    {
        "type": "comment",
        "name": "producer_question10_comment",
        "title": "Please give details:",
    },
    {
        "type": "checkbox",
        "name": "producer_question11",
        "title": "Do you ensure the provision and utilization of suitable equipment for personnel involved in the handling of hazardous chemicals, encompassing:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Goggles"
            },
            {
             "value": "Item 2",
             "text": "Personal Protection Equipment (PPE)"
            },
            {
             "value": "Item 3",
             "text": "Rubber-soled shoes"
            },
            {
             "value": "Item 4",
             "text": "Face masks"
            },
            {
             "value": "Item 5",
             "text": "Cut-resistant gloves"
            },
            {
             "value": "Item 6",
             "text": "Hearing protection"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "boolean",
        "name": "producer_question12",
        "title": "Is personnel training available regarding hazardous substances and the proper procedures for safe manual dismantling?",
    },
    {
        "type": "comment",
        "name": "producer_question12_comment",
        "title": "Please give details:",
    },
    {
        "type": "checkbox",
        "name": "producer_question13",
        "title": "In the case of exporting overseas, have you ensured that the operators:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Implement measures to mitigate the emission of hazardous substances into the environment."
            },
            {
             "value": "Item 2",
             "text": "Comply to the regulations and guidelines set by their respective nations."
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "user_question1",
        "title": "Do you comply to current legislation and best practices while handling and managing hazardous waste, including:",
        "choices": [
            {
             "value": "Item 1",
             "text": "WEEE Directive"
            },
            {
             "value": "Item 2",
             "text": "Batteries and Accumulators Directive"
            },
            {
             "value": "Item 3",
             "text": "CENELEC"
            },
            {
             "value": "Item 4",
             "text": "REACH Regulation"
            },
            {
             "value": "Item 5",
             "text": "POPs regulation"
            },
            {
             "value": "Item 6",
             "text": "Basel Convention"
            },
            {
             "value": "Item 7",
             "text": "National regulations (e.g. permit and license)"
            },
            {
             "value": "Item 8",
             "text": "Occupational Health and Safety standards"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "user_question2",
        "title": "Does your collection facility include the following features:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Provide collection points of WEEE"
            },
            {
             "value": "Item 2",
             "text": "Provide collection points of WEEE for reuse and repair"
            },
            {
             "value": "Item 3",
             "text": "Provide Bulky waste collection"
            },
            {
             "value": "Item 4",
             "text": "Easily accessible collection points"
            },
            {
             "value": "Item 5",
             "text": "Weatherproof"
            },
            {
             "value": "Item 6",
             "text": "Adequate facilities for managing leakage of compressor oils, coolant/refrigerant."
            },
            {
             "value": "Item 7",
             "text": "Safe storage for batteries, CRT, LCD/LED/Plasma TV, fluorescent and other mercury containing lamps"
            },
            {
             "value": "Item 8",
             "text": "Specific storage for reuse and repair"
            },
            {
             "value": "Item 9",
             "text": "Ensure of handling WEEE collected to the state of the art recycling / treatment"
            },
            {
             "value": "Item 10",
             "text": "Energy-efficient transport mode"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "user_question3",
        "title": "Audience groups:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Consumers"
            },
            {
             "value": "Item 2",
             "text": "Businesses"
            },
            {
             "value": "Item 3",
             "text": "Retailers"
            },
            {
             "value": "Item 4",
             "text": "Recyclers"
            },
            {
             "value": "Item 5",
             "text": "Relevant authorities"
            },
            {
             "value": "Item 6",
             "text": "Producers"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "user_question4",
        "title": "How information is generating to audience:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Guidelines"
            },
            {
             "value": "Item 2",
             "text": "Online platform and social media (website, knowledge website)"
            },
            {
             "value": "Item 3",
             "text": "Communities’ activities (e.g. repair café)"
            },
            {
             "value": "Item 4",
             "text": "Workshops"
            },
            {
             "value": "Item 5",
             "text": "Training program"
            },
            {
             "value": "Item 6",
             "text": "Events, forum, conferences"
            },
            {
             "value": "Item 7",
             "text": "Integrated in educational program"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "user_question5",
        "title": "WEEE Awareness topics that covers in your organization include:",
        "choices": [
            {
             "value": "Item 1",
             "text": "General information of WEEE"
            },
            {
             "value": "Item 2",
             "text": "Updates of law and regulations related to WEEE management"
            },
            {
             "value": "Item 3",
             "text": "Environmental and health impact of WEEE"
            },
            {
             "value": "Item 4",
             "text": "Preparation for WEEE disposal"
            },
            {
             "value": "Item 5",
             "text": "Responsible disposal of WEEE"
            },
            {
             "value": "Item 6",
             "text": "Information on collection points"
            },
            {
             "value": "Item 7",
             "text": "Information on Take-back program and EPR"
            },
            {
             "value": "Item 8",
             "text": "Information on Reuse and Repair"
            },
            {
             "value": "Item 9",
             "text": "Case studies and best practices of WEEE management"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "user_question6",
        "title": "Cooperation basis:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Regional level"
            },
            {
             "value": "Item 2",
             "text": "National level"
            },
            {
             "value": "Item 3",
             "text": "International level"
            },
            {
             "value": "Item 4",
             "text": "Public Private partnerships"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "reuser_question1",
        "title": "Do you comply to current legislation and best practices for managing hazardous components and waste, which includes:",
        "choices": [
            {
             "value": "Item 1",
             "text": "WEEE Directive"
            },
            {
             "value": "Item 2",
             "text": "RoHS Directive"
            },
            {
             "value": "Item 3",
             "text": "Batteries and Accumulators Directive"
            },
            {
             "value": "Item 4",
             "text": "Product guidelines"
            },
            {
             "value": "Item 5",
             "text": "Third party repair guideline (e.g. Ifixit ..)"
            },
            {
             "value": "Item 6",
             "text": "Occupational Health and Safety"
            },
            {
             "value": "Item 7",
             "text": "Eco-design Directive"
            },
            {
             "value": "Item 8",
             "text": "CENELEC"
            },
            {
             "value": "Item 9",
             "text": "National reuse and repair guidelines"
            },
            {
             "value": "Item 10",
             "text": "The General Data Protection Regulation (GDPR)"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "reuser_question2",
        "title": "The technical services concerns and provide services with:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Skilled technician with qualifications of electrical professionals"
            },
            {
             "value": "Item 2",
             "text": "Data sanitization"
            },
            {
             "value": "Item 3",
             "text": "Clear policies and procedures for handling returns, repairs, and warranty claims"
            },
            {
             "value": "Item 4",
             "text": "Legal guarantee and quality assurance of products"
            },
            {
             "value": "Item 5",
             "text": "Sustainable packaging"
            },
            {
             "value": "Item 6",
             "text": "Energy-efficient transport and logistic"
            },
            {
             "value": "Item 7",
             "text": "Ensure the state of the art of treatment of WEEE when not suitable for reuse and repair"
            },
            {
             "value": "Item 8",
             "text": "Promote the benefits of repaired and refurbished products"
            },
            {
             "value": "Item 9",
             "text": "Cooperation with producers, retailers and other stakeholders"
            },
            {
             "value": "none",
             "text": "None"                
            }
        ],
    },
    {
        "type": "checkbox",
        "name": "reuser_question3",
        "title": "Do you have preparation and implementation plan for digital system which include:",
        "choices": [
            {
             "value": "Item 1",
             "text": "Connecting to repair network in the regional, national level"
            },
            {
             "value": "Item 2",
             "text": "Digital system for tracking and managing inventory"
            },
            {
             "value": "Item 3",
             "text": "Digital platform for reporting of WEEE reuse and repair"
            },
            {
             "value": "Item 4",
             "text": "System support for Digital product passport"
            },
            {
             "value": "Item 5",
             "text": "Digital communication channels for customers engagement and awareness"
            },
            {
             "value": "Item 6",
             "text": "Foster collaboration and data sharing among stakeholders in the WEEE reuse and repair"
            },
            {
             "value": "Item 7",
             "text": "Encourage innovation and experimentation with new digital technologies, solutions, and business models to address emerging challenges and opportunities in WEEE reuse and repair"
            },
            {
             "value": "none",
             "text": "None"
            }
        ],
    },
    {
        "type": "boolean",
        "name": "admin_question1",
        "title": "Are you in compliance to government regulations concerning health and safety, trading standards, employment, and environmental management?",
    },
    {
        "type": "comment",
        "name": "admin_question1_comment",
        "title": "Please give details",
    },
    {
        "type": "boolean",
        "name": "admin_question2",
        "title": "Do you have a training programme for all staff?",
    },
    {
        "type": "comment",
        "name": "admin_question2_comment",
        "title": "Please give details",
    },
    {
        "type": "boolean",
        "name": "admin_question3",
        "title": "Have you carried out a health and safety risk assessment?",
    },
    {
        "type": "comment",
        "name": "admin_question3_comment",
        "title": "Please give details",
    },
    {
        "type": "boolean",
        "name": "admin_question4",
        "title": "Do you control the release of hazardous substances from any equipment you handle?",
    },
    {
        "type": "comment",
        "name": "admin_question4_comment",
        "title": "Please give details",
    },
    {
        "type": "boolean",
        "name": "admin_question5",
        "title": "Are there established protocols in place for the management of hazardous compounds that are removed throughout the recycling procedure?",
    },
    {
        "type": "comment",
        "name": "admin_question5_comment",
        "title": "Please give details",
    },
    {
        "type": "boolean",
        "name": "admin_question6",
        "title": "Is the handling of hazardous substances exclusively carried out by appropriately competent and qualified personnel?",
    },
    {
        "type": "comment",
        "name": "admin_question6_comment",
        "title": "Please give details",
    },
    {
        "type": "boolean",
        "name": "admin_question7",
        "title": "Is personnel training available regarding hazardous substances and the proper procedures for safe manual dismantling?",
    },
    {
        "type": "comment",
        "name": "admin_question7_comment",
        "title": "Please give details",
    },
];