import { create } from "zustand";

/**
 * A custom React hook that creates a centralized state management for the survey application.
 * It uses the Zustand library for state management.
 * 
 * @returns {Object} An object containing the state variables and their corresponding setter functions.
 */
export const useSurveyStore = create((set) => ({
    /**
     * The current language of the survey application.
     * @type {{ name: string, code: string }}
     */
    currentLanguage: { name: 'English', code: 'en' },

    /**
     * The results of the survey.
     * @type {string}
     */
    surveyResults: '',

    /**
     * A flag indicating whether the survey has been completed.
     * @type {boolean}
     */
    isSurveyCompleted: false,

    /**
     * A flag indicating whether the dark mode is enabled.
     * @type {boolean}
     */
    isDarkMode: false,

    /**
     * The source URL of the first pie chart image.
     * @type {string}
     */
    pieChartImageSrc1: '',

    /**
     * The source URL of the second pie chart image.
     * @type {string}
     */
    pieChartImageSrc2: '',

    /**
     * The source URL of the first bar chart image.
     * @type {string}
     */
    barChartImageSrc1: '',

    /**
     * Sets the current language.
     * @param {Object} language - The new language object.
     * @param {string} language.name - The name of the language.
     * @param {string} language.code - The code of the language.
     */
    setCurrentLanguage: (language) => set(() => ({ currentLanguage: language })),

    /**
     * Sets the survey results.
     * @param {string} results - The new survey results.
     */
    setSurveyResults: (results) => set(() => ({ surveyResults: results })),

    /**
     * Sets the survey completion status.
     * @param {boolean} isCompleted - The new completion status.
     */
    setIsSurveyCompleted: (isCompleted) => set(() => ({ isSurveyCompleted: isCompleted })),

    /**
     * Sets the dark mode status.
     * @param {boolean} mode - The new dark mode status.
     */
    setIsDarkMode: (mode) => set(() => ({ isDarkMode: mode })),

    /**
     * Sets the source URL of the first pie chart image.
     * @param {string} imageSrc - The new source URL.
     */
    setPieChartImageSrc1: (imageSrc) => set(() => ({ pieChartImageSrc1: imageSrc })),

    /**
     * Sets the source URL of the second pie chart image.
     * @param {string} imageSrc - The new source URL.
     */
    setPieChartImageSrc2: (imageSrc) => set(() => ({ pieChartImageSrc2: imageSrc })),

    /**
     * Sets the source URL of the first bar chart image.
     * @param {string} imageSrc - The new source URL.
     */
    setBarChartImageSrc1: (imageSrc) => set(() => ({ barChartImageSrc1: imageSrc })),
}));