import React, { useRef, useEffect } from 'react';
import { useSurveyStore } from '../../store/surveyStore';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BACKGROUNDCOLORS } from '../../lib/colors';
import { BORDERCOLORS } from '../../lib/colors';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

/**
 * BarChart component renders a bar chart using the Chart.js library.
 * It takes in the following props:
 * - responsesLabels: An array of labels for the x-axis of the chart.
 * - responsesData: An array of data for the y-axis of the chart.
 * - datasetName: A string representing the name of the dataset displayed on the chart.
 * - setterFunctionName: A string representing the name of the function in the store to update with the chart image.
 *
 * The component uses the useRef and useEffect hooks from React to capture the chart image after a 5-second delay.
 * The captured image is then passed to the setterFunction in the store.
 *
 * @param {Array} responsesLabels - An array of labels for the x-axis of the chart.
 * @param {Array} responsesData - An array of data for the y-axis of the chart.
 * @param {string} datasetName - A string representing the name of the dataset displayed on the chart.
 * @param {string} setterFunctionName - A string representing the name of the function in the store to update with the chart image.
 *
 * @returns {JSX.Element} - A Bar chart component rendered using the Chart.js library.
 */
function BarChart({responsesLabels, responsesData, datasetName, setterFunctionName}) {
    const setterFunction = useSurveyStore(state => state[setterFunctionName]);
    const ref = useRef();

    useEffect(() => {
        setTimeout(() => {
          const chartImage = ref.current.toBase64Image('image/png', 1);
          setterFunction(chartImage);
        }, 5000);
      }, [ref, setterFunction]);

    const options = {
        responsive: true,
    };

    const data = {
        labels: responsesLabels,
        datasets: [
            {
                label: datasetName,
                data: responsesData,
                backgroundColor: BACKGROUNDCOLORS,
                borderColor: BORDERCOLORS,
            },
        ],
    };

    return <Bar ref={ref} options={options} data={data}/>
};

export default BarChart;