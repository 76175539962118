import { QuestionsJson } from '../lib/questions';
import PieChart from '../components/charts/PieChart';
import BarChart from '../components/charts/BarChart';

/**
 * Generates charts based on the provided survey results.
 * @param {string} surveyResults - The JSON string representation of the survey results.
 * @returns {Array} An array of objects, each containing a unique chart ID, the generated chart, and the question title.
 */
export function generateCharts(surveyResults) {
    console.log('Generating charts');
    const result = [];
    const surveyResultsJson = JSON.parse(surveyResults);
    //const surveyResultsJson = surveyResults;

    /**
     * Prepares data for a chart based on the provided question JSON data.
     * @param {Object} questionJsonData - The JSON object containing the question data.
     * @returns {Array} An array containing the labels and data for the chart.
     */
    const prepareDataForChart = (questionJsonData) => {
        const labels = Object.keys(questionJsonData);
        const data = Object.values(questionJsonData).map(item => item.Value);

        return [labels, data];
    };

    /**
     * Retrieves the labels within the object based on the provided labels and question JSON data.
     * @param {Array} labels - The array of labels to be retrieved.
     * @param {Object} questionJsonData - The JSON object containing the question data.
     * @returns {Array} An array containing the retrieved labels.
     */
    const getLabelsWithinObject = (labels, questionJsonData) => {
        return labels.map(item => {
            const found = questionJsonData.rows.find(row => row.value === item);
            return found ? found.text : null;
        });
    };

    switch (true) {
        case ('specific_2' in surveyResultsJson && 'specific_3' in surveyResultsJson
            && 'specific_17' in surveyResultsJson):
                let pieChart1 = null;
                let pieChart3 = null;
                let barChart1 = null;

                //Pie Chart generation for specific_2
                let questionData1 = QuestionsJson.find(item => item.name === 'specific_2');
                let [labels1, data1] = prepareDataForChart(surveyResultsJson.specific_2);
                let longTexts1 = getLabelsWithinObject(labels1, questionData1);
                const modData1 = data1.map(dataEntry => parseInt(dataEntry, 10));
                pieChart1 = <PieChart responsesLabels={longTexts1} responsesData={modData1} setterFunctionName={'setPieChartImageSrc1'} />
                result.push({
                    id: 'PieChart_1',
                    chart: pieChart1,
                    questionTitle: questionData1.title,
                });

                //Bar Chart generation for specific_3
                let questionData4 = QuestionsJson.find(item => item.name === 'specific_3');
                let [labels4, data4] = prepareDataForChart(surveyResultsJson.specific_3);
                let longTexts4 = getLabelsWithinObject(labels4, questionData4);
                const modData4 = data4.map(dataEntry => parseInt(dataEntry, 10));
                barChart1 = <BarChart responsesLabels={longTexts4} responsesData={modData4} datasetName='Percentage of materials recovered from WEEE' setterFunctionName={'setBarChartImageSrc1'} />
                result.push({
                    id: 'BarChart_1',
                    chart: barChart1,
                    questionTitle: questionData4.title,
                });

                //Pie Chart generation for specific_17
                let questionData3= QuestionsJson.find(item => item.name === 'specific_17');
                let [labels3, data3] = prepareDataForChart(surveyResultsJson.specific_17);
                let longTexts3 = getLabelsWithinObject(labels3, questionData3);
                const modData3 = data3.map(dataEntry => parseInt(dataEntry, 10));
                pieChart3 = <PieChart responsesLabels={longTexts3} responsesData={modData3} setterFunctionName={'setPieChartImageSrc2'} />
                result.push({
                    id: 'PieChart_3',
                    chart: pieChart3,
                    questionTitle: questionData3.title,
                });
                break;
        default:
            break;
    }

    return result;
}