function removeSpecificKeys(obj) {
    // Create a new object to store the filtered key-value pairs
    const newObj = {};

    // Iterate over each key in the original object
    for (const key in obj) {
        // Check if the key does not contain 'specific'
        if (!key.includes('specific') && !key.includes('country') && !key.includes('group') && !key.includes('organization_name')) {
            // Add the key-value pair to the new object
            newObj[key] = obj[key];
        }
    }

    return newObj;
}

export const getCustomConfigFile = (surveyResultsJson, chartImages, configNumber) => {
    let configFile = {};
    if (configNumber === 1) {
        configFile = {
            parts: [
                {
                    id: 'Custom_Part_1',
                    subtitle: '5.1.4 Evaluation of WEEE Collecting and Their Impact on the Studied Area',
                    sections: [
                        {
                            id:'Custom_Part_1_Section_1',
                            type: 'text',
                            content: 'The management of Waste Electrical and Electronic Equipment (WEEE) has become a critical environmental and public health issue in recent years. Effective collecting practices are essential for mitigating the negative impacts of e-waste, recovering valuable materials, and ensuring the safe disposal of hazardous substances. This evaluation seeks to understand the current state of WEEE collecting in the studied area, including the types of WEEE most commonly collected, the efficiency of different recycling methods, and the overall societal impact of WEEE generation.',
                            space: true,
                        },
                        {
                            id:'Custom_Part_1_Section_22',
                            type: 'text',
                            content: 'Users were asked about their disposal habits and preferences, shedding light on the predominant categories of electronic waste and offering a clearer understanding of consumer behavior and the effectiveness of WEEE collecting. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_2',
                            type: 'unique_survey_question',
                            content: surveyResultsJson,
                            question_name: 'specific_1',
                            space: true,
                        },
                        {
                            id:'Custom_Part_1_Section_33',
                            type: 'text',
                            content: 'Users were asked to indicate the percentage, by weight, of each category of WEEE they disposed. This data helps to quantify the distribution of electronic waste types, offering a clearer understanding of the composition of WEEE and aiding in the assessment of current waste management practices. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_3',
                            type: 'image',
                            content: chartImages[0],
                        },
                        {
                            id:'Custom_Part_1_Section_44',
                            type: 'text',
                            content: 'Users were asked to specify the percentage of different materials, such as metals, plastics, glass and ceramics, and other materials like lithium and nickel. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_4',
                            type: 'image',
                            content: chartImages[1],
                        },
                        {
                            id: 'Custom_Part_1_Section_55',
                            type: 'text',
                            content: 'Users were asked to specify the challenged in their rural area in terms of WEEE management. This is crucial for developing tailored strategies that address the logistical, infrastructural, and awareness, ensuring effective WEEE collecting and disposal practices. The users answer related to this is:',
                        },
                        {
                            id: 'Custom_Part_1_Section_5',
                            type: 'text',
                            content: surveyResultsJson.specific_4,
                            space: true,
                        },
                        {
                            id: 'Custom_Part_1_Section_66',
                            type: 'text',
                            content: 'An external contribution has provided insights by asking users to estimate the number of outdated devices in their homes The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_6',
                            type: 'unique_survey_question',
                            content: surveyResultsJson,
                            question_name: 'specific_19',
                            space: true,
                        },
                        {
                            id: 'Custom_Part_1_Section_77',
                            type: 'text',
                            content: 'Users were asked to estimate which electronic devices are currently unrecovered and stored in their homes, contributing to the WEEE problem. The users answer related to this is:',
                        },
                        {
                            id: 'Custom_Part_1_Section_7',
                            type: 'text',
                            content: surveyResultsJson.specific_5,
                            space: true,
                        },
                        {
                            id: 'Custom_Part_1_Section_88',
                            type: 'text',
                            content: 'Users were asked to rate the effectiveness of public awareness campaigns and their correlation with WEEE collecting rates. Understanding this relationship is crucial for developing effective strategies. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_8',
                            type: 'unique_survey_question',
                            content: surveyResultsJson,
                            question_name: 'specific_6',
                            space: true,
                        },
                        {
                            id: 'Custom_Part_1_Section_99',
                            type: 'text',
                            content: 'Users were asked to evaluate the sufficiency of WEEE collection points in the studied area and to describe their distribution. This question aims to gather insights on whether current collection facilities meet public needs. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_9',
                            type: 'unique_survey_question',
                            content: surveyResultsJson,
                            question_name: 'specific_20',
                        },
                        {
                            id: 'Custom_Part_1_Section_999',
                            type: 'text',
                            content: surveyResultsJson.specific_7,
                            space: true,
                        },
                        {
                            id: 'Custom_Part_1_Section_1010',
                            type: 'text',
                            content: 'Users were asked to evaluate the significance of illegal disposal of WEEE in their area and to describe the measures being implemented to combat this issue. This question aims to gather insights on the prevalence of illegal WEEE disposal and the effectiveness of current strategies to address it. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_10',
                            type: 'unique_survey_question',
                            content: surveyResultsJson,
                            question_name: 'specific_21',
                            space: true,
                        },
                        {
                            id:'Custom_Part_1_Section_1111',
                            type: 'text',
                            content: 'Recycling Waste Electrical and Electronic Equipment involves various methods, each with its unique processes and benefits. Understanding the different techniques used for WEEE recycling in your area provides valuable insights into the effectiveness and efficiency of recycling efforts. Users were asked to identify the methods employed, ranging from manual dismantling to advanced technologies and specialized processes. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_11',
                            type: 'unique_survey_question',
                            content: surveyResultsJson,
                            question_name: 'specific_16',
                            space: true,
                        },
                        {
                            id:'Custom_Part_1_Section_1212',
                            type: 'text',
                            content: 'Users were asked to indicate the percentage of each method used for WEEE recycling in their area, providing their opinions on how prevalent each technique is. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_1_Section_12',
                            type: 'image',
                            content: chartImages[2],
                        },
                        {
                            id:'Custom_Part_1_Section_13',
                            type: 'text',
                            content: 'These results, provided by users, will offer insights into the efficiency and effectiveness of current WEEE management in their area and be useful for future evaluations and studies in their area.',
                        },
                    ],
                },
            ],
        };
    } else if (configNumber === 2) {
        configFile = {
            parts: [
                {
                    id: 'Custom_Part_2',
                    subtitle: '6.3. Economic Benefits of WEEE Recycling in the Studied Area',
                    sections: [
                        {
                            id:'Custom_Part_2_Section_1',
                            type: 'text',
                            content: 'The recycling of WEEE offers significant economic advantages, contributing to both environmental sustainability and the local economy. The economic benefits of WEEE recycling in the studied area is crucial for policymakers, businesses, and the community to appreciate the financial incentives and broader economic impact associated with effective e-waste management. This inquiry aims to explore the specific economic benefits derived from WEEE recycling practices in the area, including cost savings, revenue generation, and employment opportunities.',
                            space: true,
                        },
                        {
                            id: 'Custom_Part_2_Section_22',
                            type: 'text',
                            content: 'Economic incentives can play a crucial role in encouraging businesses and individuals to participate in WEEE collecting programs. Users were asked to evaluate whether the current incentives are sufficient and to describe any problems or positive points related to these incentives. The users answer related to this is:',
                        },
                        {
                            id:'Custom_Part_2_Section_2',
                            type: 'unique_survey_question',
                            content: surveyResultsJson,
                            question_name: 'specific_22',
                        },
                        {
                            id: 'Custom_Part_2_Section_222',
                            type: 'text',
                            content: surveyResultsJson.specific_9,
                        },
                    ],
                },
            ],
        };
    } else if (configNumber === 3) {
        if (surveyResultsJson.specific_11) {
            configFile = {
                parts: [
                    {
                        id: 'Custom_Part_3',
                        subtitle: '8. Policy Instruments for WEEE Management in the Studied Area',
                        sections: [
                            {
                                id:'Custom_Part_3_Section_1',
                                type: 'text',
                                content: 'Effective policy instruments are essential for regulating the collection, recycling, and disposal of WEEE. These policies can facilitate efficient resource recovery, reduce environmental pollution, and ensure the safe handling of hazardous materials. This inquiry seeks to determine whether specific policy instruments related to WEEE management exist in the studied area and, if so, to understand their main features, identify potential improvements, and recognize the actors involved in enhancing WEEE management practices.',
                                space: true,
                            },
                            {
                                id: 'Custom_Part_3_Section_2',
                                type: 'text',
                                content: 'Level of operation of the Action Plan:',
                            },
                            {
                                id: 'Custom_Part_3_Section_3',
                                type: 'unique_survey_question',
                                content: surveyResultsJson,
                                question_name: 'specific_10',
                                space: true,
                            },
                            {
                                id: 'Custom_Part_3_Section_4',
                                type: 'text',
                                content: 'Users were asked to describe the Geographical area:',
                            },
                            {
                                id: 'Custom_Part_3_Section_5',
                                type: 'text',
                                content: surveyResultsJson.specific_11,
                                space: true,
                            },
                            {
                                id: 'Custom_Part_3_Section_6',
                                type: 'text',
                                content: 'Users were asked to indicate the problematic in the area related to WEEE:',
                            },
                            {
                                id: 'Custom_Part_3_Section_7',
                                type: 'text',
                                content: surveyResultsJson.specific_12,
                                space: true,
                            },
                        ],
                    },
                ],
            };
        } else {
            configFile = {
                parts: [
                    {
                        id: 'Custom_Part_3',
                        subtitle: '8. Policy Instruments for WEEE Management in the Studied Area',
                        sections: [
                            {
                                id:'Custom_Part_3_Section_1',
                                type: 'text',
                                content: 'Effective policy instruments are essential for regulating the collection, recycling, and disposal of WEEE. These policies can facilitate efficient resource recovery, reduce environmental pollution, and ensure the safe handling of hazardous materials. This inquiry seeks to determine whether specific policy instruments related to WEEE management exist in the studied area and, if so, to understand their main features, identify potential improvements, and recognize the actors involved in enhancing WEEE management practices.',
                                space: true,
                            },
                            {
                                id: 'Custom_Part_3_Section_2',
                                type: 'text',
                                content: 'Level of operation of the Action Plan:',
                            },
                            {
                                id: 'Custom_Part_3_Section_3',
                                type: 'unique_survey_question',
                                content: surveyResultsJson,
                                question_name: 'specific_10',
                                space: true,
                            },
                            {
                                id: 'Custom_Part_3_Section_6',
                                type: 'text',
                                content: 'Users were asked to indicate the problematic in the area related to WEEE:',
                            },
                            {
                                id: 'Custom_Part_3_Section_7',
                                type: 'text',
                                content: surveyResultsJson.specific_12,
                                space: true,
                            },
                        ],
                    },
                ],
            };
        }

        if (surveyResultsJson.specific_13_comment) {
            configFile.parts[0].sections.push({
                id: 'Custom_Part_3_Section_8',
                type: 'text',
                content: 'Users were asked to indicate if there is any policy instrument in the area.',
            });

            configFile.parts[0].sections.push({
                id: 'Custom_Part_3_Section_9',
                type: 'text',
                content: surveyResultsJson.specific_13_comment,
                space: true,
            });

            configFile.parts[0].sections.push({
                id: 'Custom_Part_3_Section_10',
                type: 'text',
                content: 'Users were asked to describe the main features of this policy instrument:',
            });

            configFile.parts[0].sections.push({
                id: 'Custom_Part_3_Section_11',
                type: 'text',
                content: surveyResultsJson.specific_14,
                space: true,
            });

            configFile.parts[0].sections.push({
                id: 'Custom_Part_3_Section_12',
                type: 'text',
                content: 'Users were asked to indicate improvements that can be implemented for this instrument:',
            });

            configFile.parts[0].sections.push({
                id: 'Custom_Part_3_Section_13',
                type: 'text',
                content: surveyResultsJson.specific_15,
                space: true,
            });
        }

        configFile.parts[0].sections.push({
            id: 'Custom_Part_3_Section_14',
            type: 'survey_results',
            content: removeSpecificKeys(surveyResultsJson),
        });

    } else if (configNumber === 4) {
        configFile = {
            parts: [
                {
                    id: 'Custom_Part_Add',
                    subtitle: '5.1.1. Situation of the WEEE Policy',
                    additionalSubtitle: 'Legal Framework in Chequia',
                    sections: [
                        {
                            id:'Custom_Part_Add_Section_1',
                            type: 'text',
                            content: 'Situation of the WEEE Policy',
                        },
                        {
                            id: 'Custom_Part_Add_Section_2',
                            type: 'text',
                            content: surveyResultsJson.specific_18,
                            space: true,
                        },
                    ],
                },
            ],
        };
    } else if (configNumber === 5) {
        configFile = {
            parts: [
                {
                    id: 'Custom_Part_Conclussions',
                    sections: [
                        {
                            id: 'Custom_Part_Conclussions_Section_0',
                            type: 'text',
                            content: 'Conclussions based on the answers provided in the online assessment tool.',
                            space: true,
                        },
                    ],
                },
            ],
        };
    
        if (surveyResultsJson.specific_19 === 'Item 1' || surveyResultsJson.specific_19 === 'Item 2') {
            configFile.parts[0].sections.push({
                id: 'Custom_Part_Conclussions_Section_1',
                type: 'text',
                content: 'As the users select Very High (>15) or High (>10) in the number of devices that they have in their house that should be collected, the conclusions are a significant need for increased awareness campaigns about the importance of proper electronic waste disposal and more convenient collection programs. Implementing educational initiatives, enhancing collection services, and providing incentives can encourage users to recycle their outdated devices, thereby reducing the environmental impact of electronic waste.',
            });
        }

        if (surveyResultsJson.specific_6['Row 1'].Value === 'Very low' || surveyResultsJson.specific_6['Row 1'].Value === 'Low' ||
            surveyResultsJson.specific_6['Row 2'].Value === 'Very low' || surveyResultsJson.specific_6['Row 2'].Value === 'Low') {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_2',
                    type: 'text',
                    content: 'As users select "low" or "very low" in the correlation between public awareness campaigns and WEEE collecting, it indicates that these campaigns are not effectively driving WEEE collecting efforts. To address this, better awareness can be achieved through the implementation of the WEEE Aware pilot action. This initiative involves starting waste awareness from an early age and maintaining it at higher levels.',
                });
            }

        if (surveyResultsJson.specific_20 === 'Item 3' || surveyResultsJson.specific_20 === 'Item 4') {
            configFile.parts[0].sections.push({
                id: 'Custom_Part_Conclussions_Section_3',
                type: 'text',
                content: 'As users select "Insufficient" or "Very Insufficient" related to whether there are enough WEEE collection points across the studied area, it indicates that the current infrastructure does not adequately meet the needs of the population. To address this issue, it is necessary to analyze the mapping of collection points and consider adding more. Implementing the WEEE Collect/Market pilot action can be particularly effective. This initiative leverages digital tools to solve collection problems without significant investment.',
            });
        }

        if (surveyResultsJson.specific_21 === 'Item 1' || surveyResultsJson.specific_21 === 'Item 2') {
            configFile.parts[0].sections.push({
                id: 'Custom_Part_Conclussions_Section_4',
                type: 'text',
                content: 'As users select "Very Significant" or "Significant" related to illegal disposal of WEEE in their area, it indicates that this is a major issue. To address this problem, it is essential to enhance enforcement of existing regulations and increase public awareness about the environmental and legal consequences of illegal disposal.',
            });
        }

        if (surveyResultsJson.specific_22 === 'Item 3' || surveyResultsJson.specific_22 === 'Item 4') {
            configFile.parts[0].sections.push({
                id: 'Custom_Part_Conclussions_Section_5',
                type: 'text',
                content: 'As users select "Insufficient" or "Very Insufficient" related to economic incentives to encourage participation in WEEE collecting programs, it indicates that current incentives are not effectively motivating businesses and individuals. To address this, authorities should consider enhancing existing incentives or introducing new ones, such as financial rewards, tax benefits, or subsidies for active participation in WEEE collection. Additionally, raising awareness about the available incentives and simplifying the process to access them could improve participation rates.',
            });
        }

        if (surveyResultsJson.group === 'Item 1') {
            if (surveyResultsJson.producer_question3 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_6',
                    type: 'text',
                    content: 'As user selected "NO" in compliance to government regulations concerning health and safety, trading standards, employment, and environmental management it highlights significant importance to implement these immediate improvements.',
                });
            }

            if (surveyResultsJson.producer_question4 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_7',
                    type: 'text',
                    content: 'As users selected "NO" to having a training programme for all staff, it is essential to implement comprehensive training initiatives.',
                });
            }

            if (surveyResultsJson.producer_question5 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_8',
                    type: 'text',
                    content: 'As users selected "NO" to carrying out a health and safety risk assessment, it is crucial to conduct these assessments immediately.',
                });
            }

            if (surveyResultsJson.producer_question7 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_9',
                    type: 'text',
                    content: 'As users selected "NO" to controlling the release of hazardous substances from any equipment they handle, it is vital to establish and enforce strict control measures.',
                });
            }

            if (surveyResultsJson.producer_question8 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_10',
                    type: 'text',
                    content: 'As users selected "NO" to having established protocols in place for the management of hazardous compounds removed during the recycling procedure, it is imperative to develop and implement these protocols.',
                });
            }

            if (surveyResultsJson.producer_question10 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_11',
                    type: 'text',
                    content: 'As users selected "NO" to handling hazardous substances exclusively by appropriately competent and qualified personnel, it is crucial to ensure that only trained and qualified individuals manage these substances.',
                });
            }

            if (surveyResultsJson.producer_question12 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_12',
                    type: 'text',
                    content: 'As users selected "NO" to personnel training availability regarding hazardous substances and proper procedures for safe manual dismantling, it is essential to implement comprehensive training programs.',
                });
            }
        }

        if (surveyResultsJson.group === 'Item 4') {
            if (surveyResultsJson.admin_question1 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_6',
                    type: 'text',
                    content: 'As user selected "NO" in compliance to government regulations concerning health and safety, trading standards, employment, and environmental management it highlights significant importance to implement these immediate improvements.',
                });
            }

            if (surveyResultsJson.admin_question2 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_7',
                    type: 'text',
                    content: 'As users selected "NO" to having a training programme for all staff, it is essential to implement comprehensive training initiatives.',
                });
            }

            if (surveyResultsJson.admin_question3 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_8',
                    type: 'text',
                    content: 'As users selected "NO" to carrying out a health and safety risk assessment, it is crucial to conduct these assessments immediately.',
                });
            }

            if (surveyResultsJson.admin_question4 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_9',
                    type: 'text',
                    content: 'As users selected "NO" to controlling the release of hazardous substances from any equipment they handle, it is vital to establish and enforce strict control measures.',
                });
            }

            if (surveyResultsJson.admin_question5 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_10',
                    type: 'text',
                    content: 'As users selected "NO" to having established protocols in place for the management of hazardous compounds removed during the recycling procedure, it is imperative to develop and implement these protocols.',
                });
            }

            if (surveyResultsJson.admin_question6 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_11',
                    type: 'text',
                    content: 'As users selected "NO" to handling hazardous substances exclusively by appropriately competent and qualified personnel, it is crucial to ensure that only trained and qualified individuals manage these substances.',
                });
            }

            if (surveyResultsJson.admin_question7 === false) {
                configFile.parts[0].sections.push({
                    id: 'Custom_Part_Conclussions_Section_12',
                    type: 'text',
                    content: 'As users selected "NO" to personnel training availability regarding hazardous substances and proper procedures for safe manual dismantling, it is essential to implement comprehensive training programs.',
                });
            }
        }
    }

    return configFile;
};