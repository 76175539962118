import { Page, Document, StyleSheet, Image, Text, View } from "@react-pdf/renderer";
import frontPageBottom from '../../../assets/pdf-header/frontPageBottom.png';
import imageLine from '../../../assets/pdf-header/imageLine.png';
import imageLogo from '../../../assets/pdf-header/imageLogo.png';

const styles = StyleSheet.create({
    body: {
        fontFamily: 'Arial',
        paddingTop: 35,
        paddingBottom: 65,
    },
    container: {
        marginTop: 60,
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'stretch',
        alignItems: 'stretch',
        paddingHorizontal: 70,
    },
    title: {
        marginLeft: 70,
        marginBottom: 10,
        fontSize: 30,
        color: 'rgb(140 180 223)',
    },
    subtitle: {
        marginLeft: 70,
        marginBottom: 10,
        fontSize: 16,
        color: 'rgb(54 91 183)',
    },
    projectName: {
        textAlign: 'center',
        fontSize: 14,
        color: 'rgb(54 91 183)',
    },
    textDate: {
        fontSize: 10,
        flexBasis: 'auto',
        alignSelf: 'stretch',
        color: 'rgb(54 91 183)',
    },
    image: {
        marginBottom: 10,
        paddingLeft: 70,
        paddingRight: 40,
    },
    imageLogo: {
        paddingHorizontal: 110,
    },
    imageBottom: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
});

const CustomFrontPage = ({ organization_name }) => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${month} ${year}`;

    return (
        <Document>
            <Page>
                <View style={styles.container}>
                    <Text style={styles.title}>REGIONAL ACTION</Text>
                    <Text style={styles.title}>PLAN</Text>
                    <Text style={styles.subtitle}>for waste management of electrical</Text>
                    <Text style={styles.subtitle}>and electronic equipment</Text>
                </View>
                <View>
                    <Image
                        style={styles.image}
                        source={imageLine}
                    />
                    <View style={styles.flexContainer}>
                        <Text style={styles.textDate}>{organization_name}</Text>
                        <Text style={styles.textDate}>{formattedDate}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <Image
                        style={styles.imageLogo}
                        source={imageLogo}
                    />
                    <Text style={styles.projectName}>Design and test of policies for reducing, repairing,</Text>
                    <Text style={styles.projectName}>recovering and reusing waste from electrical,</Text>
                    <Text style={styles.projectName}>electronic equipment and plastic in Central Europe.</Text>
                </View>
                <Image
                    style={styles.imageBottom}
                    source={frontPageBottom}
                />
            </Page>
        </Document>
    );
}

export default CustomFrontPage;