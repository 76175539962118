import { pdf as reactPDF } from '@react-pdf/renderer';
import PDFMerger from 'pdf-merger-js/browser';
import CustomFrontPage from '../components/pdf-generator/custom_front_page/CustomFrontPage';
/**
 * Generates an alternative result PDF by merging multiple PDFs.
 *
 * @param {string} surveyResults - The JSON string of the survey results.
 * @returns {Blob} - A Blob object representing the merged PDF.
 */
export async function generateAlternativeResultPdf(surveyResults) {
    /**
     * An array to store the Blob objects of the PDFs to be merged.
     *
     * @type {Blob[]}
     */
    let pdfsToMerge = [];
    const asPdf = reactPDF();
    const surveyResultsJson = JSON.parse(surveyResults);

    // Pdf 0 - Front Page
    const frontPage = <CustomFrontPage organization_name={surveyResultsJson.organization_name} />;
    asPdf.updateContainer(frontPage);
    let frontPagePdf = await asPdf.toBlob();
    pdfsToMerge.push(frontPagePdf);

    const generalPdfResponse1 = await fetch('/pdfs/General/CWEEEP_General_1.pdf');
    const generalPdf1 = await generalPdfResponse1.blob();
    pdfsToMerge.push(generalPdf1);

    const generalPdfResponse2 = await fetch('/pdfs/General/CWEEEP_General_2.pdf');
    const generalPdf2 = await generalPdfResponse2.blob();
    pdfsToMerge.push(generalPdf2);

    const generalPdfResponse3 = await fetch('/pdfs/General/CWEEEP_General_3.pdf');
    const generalPdf3 = await generalPdfResponse3.blob();
    pdfsToMerge.push(generalPdf3);

    const generalPdfResponse4 = await fetch('/pdfs/General/CWEEEP_General_4.pdf');
    const generalPdf4 = await generalPdfResponse4.blob();
    pdfsToMerge.push(generalPdf4);

    const generalPdfResponse5 = await fetch('/pdfs/General/CWEEEP_General_5.pdf');
    const generalPdf5 = await generalPdfResponse5.blob();
    pdfsToMerge.push(generalPdf5);

    const generalPdfResponse6 = await fetch('/pdfs/General/CWEEEP_General_6.pdf');
    const generalPdf6 = await generalPdfResponse6.blob();
    pdfsToMerge.push(generalPdf6);

    // Create a PDFMerger instance and add all the PDFs in the pdfsToMerge array.
    let merger = new PDFMerger();
    for (const file of pdfsToMerge) {
        await merger.add(file, null);
    }

    // Save the merged PDF as a Blob.
    const mergedPdf = await merger.saveAsBlob();

    // Return the merged PDF as a Blob.
    return mergedPdf;
}