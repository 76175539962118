import { Page, Document, StyleSheet, Image, Text, View } from "@react-pdf/renderer";
import header from '../../../assets/pdf-header/header.png';
import { QuestionsJson } from '../../../lib/questions';

const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      backgroundColor: 'rgb(255 255 255)',
      paddingBottom: 15,
    },
    body: {
      marginBottom: 55,
      paddingHorizontal: 60,
    },
    chart: {
      marginTop: 15,
      width: '80%',
      height: 300,
      marginBottom: 15,
      alignItems: 'center',
    },
    title: {
      fontSize: 18,
      color: 'rgb(94 116 128)',
      paddingTop: 5,
      paddingBottom: 10,
    },
    subtitle: {
      fontSize: 14,
      color: 'rgb(126 155 161)',
      paddingTop: 5,
      paddingBottom: 10,
    },
    text: {
      paddingTop: 5,
      fontSize: 11,
      lineHeight: 1.5,
      textAlign: 'justify',
    },
    header: {
      marginBottom: 20,
    },
    viewContainer: {
      paddingTop: 5,
    },
    questionTitle: {
        lineHeight: 1.5,
        paddingTop: 5,
        fontSize: 11,
        fontWeight: 'medium',
    },
    questionResponse: {
        lineHeight: 1.5,
        fontSize: 11,
        fontWeight: 'normal',
    },
});

const hasSubstring = (mainString, subString) => {
  return mainString.includes(subString);
};

const CustomSection = ({ config }) => {
  return (
    <Document>
        <Page size="A4" style={styles.page} >
            <Image
                src={header}
                style={styles.header}
                fixed
            />
            <View style={styles.body}>
              {
                config.parts.map(part => {
                  return (
                    <View key={part.id}>
                      <Text style={{...styles.subtitle}}>{part.subtitle}</Text>
                      {
                        part.additionalSubtitle &&
                        <Text style={{...styles.subtitle}}>{part.additionalSubtitle}</Text>
                      }
                      {
                        part.sections.map(section => {
                          if (section.type === 'text'){
                            return (
                              <Text key={section.id} style={{...styles.text, paddingBottom: section.space ? 10 : 0, textDecoration: section.underline ? 'underline' : 'none'}}>{section.content}</Text>
                            );
                          } else if (section.type === 'image') {
                            return (
                              <View key={section.id} style={{...styles.chart}}>
                                <Image src={section.content} />
                              </View>
                            );
                          } else if (section.type === 'unique_survey_question') {
                            return (
                              <View key={section.id} style={{...styles.viewContainer}}>
                                {
                                  Object.entries(section.content).map(([key, value]) => {
                                    if (key === section.question_name && !hasSubstring(key, '-total')){
                                        const matchingQuestion = QuestionsJson.find(question => question.name === key);
                                        if (matchingQuestion) {
                                            return (
                                                <View key={key} style={{paddingBottom: section.space ? 10 : 0}}>
                                                    {
                                                        matchingQuestion.type === 'checkbox' &&
                                                        value.map(item => {
                                                            const matchingCheckboxItem = matchingQuestion.choices.find(checkboxItem => checkboxItem.value === item);
                                                            return (
                                                                <Text style={styles.questionResponse} key={`${key}-${value}-${matchingCheckboxItem.text}`}>
                                                                    {`\t\t  ${matchingCheckboxItem.text}`}
                                                                </Text>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        matchingQuestion.type === 'radiogroup' &&
                                                        matchingQuestion.choices.find(dropdownItem => dropdownItem.value === value)? (
                                                            <Text style={styles.questionResponse}>
                                                                {`\t\t  ${matchingQuestion.choices.find(dropdownItem => dropdownItem.value === value).text}`}
                                                            </Text>
                                                        ) : null
                                                    }
                                                    {
                                                        matchingQuestion.type === 'dropdown' &&
                                                        matchingQuestion.choices.find(dropdownItem => dropdownItem.value === value)? (
                                                            <Text style={styles.questionResponse}>
                                                                {`\t\t  ${matchingQuestion.choices.find(dropdownItem => dropdownItem.value === value).text}`}
                                                            </Text>
                                                        ) : null
                                                    }
                                                    {
                                                        matchingQuestion.type === 'matrixdropdown' &&
                                                        Object.entries(value).filter(([entryKey, entryValue]) => value.hasOwnProperty(entryKey)).map(([entryKey, entryValue]) => {
                                                            const matchingOption = matchingQuestion.rows.find(row => row.value === entryKey)
                                                            return (
                                                                <Text key={`${key}-${entryKey}-${matchingOption.text}`} style={styles.questionResponse}>
                                                                    {`\t\t  ${matchingOption.text} ${Object.entries(entryValue).map(([valueKey, valueValue]) => {
                                                                        return `- ${valueKey} : ${valueValue}`;
                                                                    })}`}
                                                                </Text>                                                    
                                                            );
                                                        })
                                                    }
                                                </View>
                                            );
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return null;
                                    }
                                  })
                                }                                
                              </View>
                            );
                          } else if (section.type === 'survey_results') {
                              return (
                                <View key={section.id} style={{...styles.viewContainer}}>
                                  {
                                    Object.entries(section.content).map(([key, value]) => {
                                        if (!hasSubstring(key, '-total')){
                                            const matchingQuestion = QuestionsJson.find(question => question.name === key);
                                            if (matchingQuestion) {
                                                return (
                                                    <View key={key} style={{paddingBottom: 10}}>
                                                        <Text style={styles.questionTitle}>
                                                            {`${matchingQuestion.title}\n`}
                                                        </Text>
                                                        {
                                                            matchingQuestion.type === 'checkbox' &&
                                                            value.map(item => {
                                                                const matchingCheckboxItem = matchingQuestion.choices.find(checkboxItem => checkboxItem.value === item);
                                                                return (
                                                                    <Text style={styles.questionResponse} key={`${key}-${value}-${matchingCheckboxItem.text}`}>
                                                                        {`\t\t  ${matchingCheckboxItem.text}`}
                                                                    </Text>
                                                                );
                                                            })
                                                        }
                                                        {
                                                            matchingQuestion.type === 'radiogroup' &&
                                                            matchingQuestion.choices.find(dropdownItem => dropdownItem.value === value)? (
                                                                <Text style={styles.questionResponse}>
                                                                    {`\t\t  ${matchingQuestion.choices.find(dropdownItem => dropdownItem.value === value).text}`}
                                                                </Text>
                                                            ) : null
                                                        }
                                                        {
                                                            matchingQuestion.type === 'dropdown' &&
                                                            matchingQuestion.choices.find(dropdownItem => dropdownItem.value === value)? (
                                                                <Text style={styles.questionResponse}>
                                                                    {`\t\t  ${matchingQuestion.choices.find(dropdownItem => dropdownItem.value === value).text}`}
                                                                </Text>
                                                            ) : null
                                                        }
                                                        {
                                                            matchingQuestion.type === 'matrixdropdown' &&
                                                            Object.entries(value).filter(([entryKey, entryValue]) => value.hasOwnProperty(entryKey)).map(([entryKey, entryValue]) => {
                                                                const matchingOption = matchingQuestion.rows.find(row => row.value === entryKey)
                                                                return (
                                                                    <Text key={`${key}-${entryKey}-${matchingOption.text}`} style={styles.questionResponse}>
                                                                        {`\t\t  ${matchingOption.text} ${Object.entries(entryValue).map(([valueKey, valueValue]) => {
                                                                            return `- ${valueKey} : ${valueValue}`;
                                                                        })}`}
                                                                    </Text>                                                    
                                                                );
                                                            })
                                                        }
                                                        {
                                                            matchingQuestion.type === 'boolean' &&
                                                            <Text style={styles.questionResponse}>
                                                                {`\t\t  ${value ? 'Yes' : 'No'}`}
                                                            </Text>
                                                        }
                                                        {
                                                            matchingQuestion.type!== 'checkbox' && matchingQuestion.type!== 'dropdown'
                                                            && matchingQuestion.type !== 'matrixdropdown'
                                                            && matchingQuestion.type !== 'radiogroup' && matchingQuestion.type!== 'boolean' &&
                                                            <Text style={styles.questionResponse}>
                                                                {`\t\t  ${value}`}
                                                            </Text>
                                                        }
                                                    </View>
                                                );
                                            } else {
                                                return null;
                                            }
                                        } else {
                                            return null;
                                        }
                                    })
                                  }
                                </View>
                              );
                          } else {
                            return null;
                          }
                        })
                      }

                    </View>
                  )
                })
              }
            </View>
        </Page>
    </Document>
  );
};

export default CustomSection;