import DarkModeToggle from "react-dark-mode-toggle";
import { useSurveyStore } from '../store/surveyStore';

/**
 * Header component for the application.
 *
 * @returns {JSX.Element} The rendered JSX for the header.
 */
function Header() {
    /**
     * @description Hook to access the survey store's state and actions.
     * @param {Function} selector - A function that takes the store's state and returns the desired values.
     * @returns {Object} An object containing the current state values and action functions.
     */
    const { isDarkMode, setIsDarkMode } = useSurveyStore(state => state);

    /**
     * @description Handles the theme change event.
     * @param {Boolean} mode - The new theme mode to apply.
     */
    const handleThemeChange = (mode) => {
        setIsDarkMode(mode);
        document.documentElement.classList.toggle('dark', mode);
        localStorage.setItem('darkMode', mode);
    }

    return (
        <nav className="backdrop-blur-md bg-[#86E1C3] dark:bg-[#6C6C6C] rounded-lg shadow fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
            <ul className="max-w-[1600px] text-center md:flex md:flex-wrap md:text-left items-center justify-between p-4">
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src="logos/circular-weeep-logo.svg" alt="Circular WEEEP Logo" className="md:w-48 lg:w-96 h-32" loading='lazy'/>
                </li>
                <li className="">
                    <DarkModeToggle 
                        onChange={() => handleThemeChange(!isDarkMode)}
                        checked={isDarkMode}
                    />
                </li>
            </ul>
        </nav>
    )
}

export default Header