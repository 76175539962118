import { pdf as reactPDF } from '@react-pdf/renderer';
import PDFMerger from 'pdf-merger-js/browser';
import { COUNTRIES } from '../lib/countries';
import { getCustomConfigFile } from './getCustomConfigFile';
import CustomSection from '../components/pdf-generator/custom_section/CustomSection';
import CustomFrontPage from '../components/pdf-generator/custom_front_page/CustomFrontPage';

/**
 * Generates a final result PDF combining various PDFs based on the provided survey results and chart images.
 * @param {string} surveyResults - The JSON string representation of the survey results.
 * @param {Array<Blob>} chartImages - An array of Blob objects representing the chart images.
 * @returns {Promise<Blob>} - A Promise that resolves to a Blob object representing the final merged PDF.
 */
export async function generateFinalResultPdf(surveyResults, chartImages) {
    const asPdf = reactPDF();
    const surveyResultsJson = JSON.parse(surveyResults);
    //const surveyResultsJson = surveyResults;
    let country = surveyResultsJson.country;
    let pdfsToMerge = [];
    
    if (COUNTRIES.includes(country)) {
        // Pdf 0 - Front Page
        const frontPage = <CustomFrontPage organization_name={surveyResultsJson.organization_name} />;
        asPdf.updateContainer(frontPage);
        let frontPagePdf = await asPdf.toBlob();
        pdfsToMerge.push(frontPagePdf);

        // Pdf 1 - Logo & Index
        const generalPdfResponse1 = await fetch('/pdfs/General/CWEEEP_General_1.pdf');
        const generalPdf1 = await generalPdfResponse1.blob();
        pdfsToMerge.push(generalPdf1);

        // Pdf 2 - summary - abbreviatons
        const specificPdfResponse1 = await fetch(`/pdfs/${country}/CWEEEP_${country}_1.pdf`);
        const specificPdf1 = await specificPdfResponse1.blob();
        pdfsToMerge.push(specificPdf1);

        // Pdf 3 - ch3 - ch5
        const generalAuxPdfResponse1 = await fetch('/pdfs/General/CWEEEP_General_2.pdf');
        const generalAuxPdf1 = await generalAuxPdfResponse1.blob();
        pdfsToMerge.push(generalAuxPdf1);

        // Pdf 4 - ch5 - ch5.1.4
        const specificPdfAuxResponse1 = await fetch(`/pdfs/${country}/CWEEEP_${country}_2.pdf`);
        const specificAuxPdf1 = await specificPdfAuxResponse1.blob();
        pdfsToMerge.push(specificAuxPdf1);

        // Pdf Additional - ch5.1.1
        if (country === 'Czech_Republic') {
            const customPartConfigAdd = getCustomConfigFile(surveyResultsJson, chartImages, 4);
            const customPartAdd = <CustomSection config={customPartConfigAdd} />;
            asPdf.updateContainer(customPartAdd);
            let customPartAddPdf = await asPdf.toBlob();
            pdfsToMerge.push(customPartAddPdf);            

            const additionalPdfResponse1 = await fetch(`/pdfs/${country}/CWEEEP_${country}_Additional.pdf`);
            const additionalPdf1 = await additionalPdfResponse1.blob();
            pdfsToMerge.push(additionalPdf1);
        }

        // Pdf 5 - Custom response 5.1.4
        const customPartConfig1 = getCustomConfigFile(surveyResultsJson, chartImages, 1);
        const customPart1 = <CustomSection config={customPartConfig1} />;
        asPdf.updateContainer(customPart1);
        let customPart1Pdf = await asPdf.toBlob();
        pdfsToMerge.push(customPart1Pdf);

        // Pdf 6 - ch5.1.4 - ch6.2
        const specificPdfResponse2 = await fetch(`/pdfs/${country}/CWEEEP_${country}_3.pdf`);
        const specificPdf2 = await specificPdfResponse2.blob();
        pdfsToMerge.push(specificPdf2);

        // Pdf 7 - Custom response 6.3
        const customPartConfig2 = getCustomConfigFile(surveyResultsJson, chartImages, 2);
        const customPart2 = <CustomSection config={customPartConfig2} />;
        asPdf.updateContainer(customPart2);
        let customPart2Pdf = await asPdf.toBlob();
        pdfsToMerge.push(customPart2Pdf);

        // Pdf 8 - ch7 - ch8
        const generalPdfResponse2 = await fetch('/pdfs/General/CWEEEP_General_3.pdf');
        const generalPdf2 = await generalPdfResponse2.blob();
        pdfsToMerge.push(generalPdf2);

        // Pdf 9 - Custom response 8
        let customPartConfig3 = getCustomConfigFile(surveyResultsJson, chartImages, 3);;
        const customPart3 = <CustomSection config={customPartConfig3} />;
        asPdf.updateContainer(customPart3);
        let customPart3Pdf = await asPdf.toBlob();
        pdfsToMerge.push(customPart3Pdf);

        // Pdf 10 - ch9 - ch10
        const generalPdfResponse3 = await fetch('/pdfs/General/CWEEEP_General_4.pdf');
        const generalPdf3 = await generalPdfResponse3.blob();
        pdfsToMerge.push(generalPdf3);

        // Pdf 11 - ch11
        const specificPdfAuxResponse2 = await fetch(`/pdfs/${country}/CWEEEP_${country}_4.pdf`);
        const specificPdfAux2 = await specificPdfAuxResponse2.blob();
        pdfsToMerge.push(specificPdfAux2);

        // Pdf 12 - Custom conclussions
        let customPartConfig4 = getCustomConfigFile(surveyResultsJson, chartImages, 5);;
        const customPart4 = <CustomSection config={customPartConfig4} />;
        asPdf.updateContainer(customPart4);
        let customPart4Pdf = await asPdf.toBlob();
        pdfsToMerge.push(customPart4Pdf);

        // Pdf 13 - ch12
        const generalPdfAuxResponse4 = await fetch('/pdfs/General/CWEEEP_General_5.pdf');
        const generalPdfAux4 = await generalPdfAuxResponse4.blob();
        pdfsToMerge.push(generalPdfAux4);

        // Pdf 13 - ch12
        const generalPdfAuxResponse3 = await fetch('/pdfs/General/CWEEEP_General_6.pdf');
        const generalPdfAux3 = await generalPdfAuxResponse3.blob();
        pdfsToMerge.push(generalPdfAux3);

        // Pdf 14 - ch13
        const specificPdfAuxResponse3 = await fetch(`/pdfs/${country}/CWEEEP_${country}_5.pdf`);
        const specificPdfAux3 = await specificPdfAuxResponse3.blob();
        pdfsToMerge.push(specificPdfAux3);
    } else {
        throw new Error('Invalid country');
    }
    
    // Merge PDFs
    let merger = new PDFMerger();
    for (const file of pdfsToMerge) {
        await merger.add(file, null);
    }

    const mergedPdf = await merger.saveAsBlob();

    return mergedPdf;
}