import { useCallback, useEffect, useMemo } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { surveyJson } from '../lib/surveyJson';
import { lightTheme } from '../lib/theme_light';
import { darkTheme } from '../lib/theme_dark';
import { useSurveyStore } from '../store/surveyStore';
import 'survey-core/survey.i18n';

/**
 * SurveyComponent is a React component that renders a Survey based on the provided surveyJson and applies the appropriate theme based on the isDarkMode state.
 * It also sets the locale of the survey based on the currentLanguage state and displays the results when the survey is completed.
 * @returns {JSX.Element} - A JSX element representing the rendered Survey component.
 */
function SurveyComponent() {
  const { isDarkMode, currentLanguage, setSurveyResults, setIsSurveyCompleted } = useSurveyStore(
    state => state
  );

  const survey = useMemo(() => {
    /**
     * Creates a new Model instance with the provided surveyJson and applies the light theme.
     *
     * @param {Object} surveyJson - The JSON representation of the survey.
     * @returns {Model} - A new Model instance with the provided surveyJson and light theme.
     */
    const s = new Model(surveyJson);
    s.applyTheme(lightTheme);
    return s;
  }, []);

  const displayResults = useCallback((sender) => {
    /**
     * Converts the sender's data to a JSON string and updates the survey results and isSurveyCompleted state.
     *
     * @param {Object} sender - The sender object containing the survey data.
     */
    let data = JSON.stringify(sender.data, null, 4);
    setSurveyResults(data);
    setIsSurveyCompleted(true);
  }, [setSurveyResults, setIsSurveyCompleted]);

  survey.onComplete.add(displayResults);

  useEffect(() => {
    /**
     * Sets the locale of the survey based on the currentLanguage state.
     */
    survey.locale = currentLanguage.code;
  }, [currentLanguage, survey]);

  useEffect(() => {
    /**
     * Applies the appropriate theme based on the isDarkMode state.
     */
    if (isDarkMode) {
      survey.applyTheme(darkTheme);
    } else {
      survey.applyTheme(lightTheme);
    }
  }, [isDarkMode, survey]);

  return <Survey model={survey} />;
}

export default SurveyComponent;