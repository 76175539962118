import { useEffect, useState, useMemo } from "react";
import PDFMerger from "pdf-merger-js/browser";
import ReactGA from "react-ga4";
import { useSurveyStore } from "../store/surveyStore";
import { CONSTANTS } from "../lib/constants";
import { generateCharts } from "../utils/generateCharts";
import { generateFinalResultPdf } from "../utils/generateFinalResultPdf";
import { generateAlternativeResultPdf } from "../utils/generateAlternativeResultPdf";

/**
 * ResultComponent is a React component that displays the result of a questionnaire.
 * It includes a reset button, a button to generate a PDF file, and a section with charts and information about the answered questions.
 * @returns {JSX.Element} - A JSX element representing the result component.
 */
function ResultComponent() {
  const {
    pieChartImageSrc1,
    pieChartImageSrc2,
    barChartImageSrc1,
    surveyResults,
    setSurveyResults,
    setIsSurveyCompleted,
  } = useSurveyStore((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadPdf, setDownloadPdf] = useState();
  const fileName = "Result";

  const charts = useMemo(() => {
    return generateCharts(surveyResults);
  }, [surveyResults]);

  const handleReset = () => {
    setSurveyResults("");
    setIsSurveyCompleted(false);
  };

  const handleClick = async () => {
    setIsLoading(true);

    ReactGA.event({
      category: "User",
      action: "Downloaded PDF",
    });

    let merger = new PDFMerger();
    await merger.add(downloadPdf, null);
    await merger.save(fileName);

    setIsLoading(false);
  };

  useEffect(() => {
    console.log("Generating PDF file");
    setIsLoading(true);

    let condition = true;
    const surveyResultsJson = JSON.parse(surveyResults);
    //const surveyResultsJson = surveyResults;
    switch (true) {
      case "specific_2" in surveyResultsJson &&
        "specific_3" in surveyResultsJson &&
        "specific_17" in surveyResultsJson:
        condition =
          pieChartImageSrc1 !== "" &&
          pieChartImageSrc2 !== "" &&
          barChartImageSrc1 !== "";
        break;

      default:
        condition = true;
        break;
    }

    if (condition) {
      generateFinalResultPdf(surveyResults, [
        pieChartImageSrc1,
        pieChartImageSrc2,
        barChartImageSrc1,
      ])
        .then((result) => {
          setDownloadPdf(result);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(true);

          console.error(error);
          console.error("Generating alternative PDF file");

          generateAlternativeResultPdf(surveyResults)
            .then((result) => {
              setDownloadPdf(result);
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(true);

              console.error(error);
              console.error(
                "Error generating alternative PDF file. No possible alternative PDF generated"
              );
            });
        });
    } else {
      console.log("Condition not met");
      setIsLoading(true);
    }
  }, [barChartImageSrc1, pieChartImageSrc1, pieChartImageSrc2, surveyResults]);

  return (
    <section className="p-6 bg-[#f0f0f0] dark:bg-[#1B1B1B] flex flex-col">
      <div className="text-center mb-2">
        <button
          type="button"
          onClick={() => handleReset()}
          className="text-white bg-[#F7BE38] hover:bg-[#F7BE38]/80 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:focus:ring-[#F7BE38]/50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-refresh size-6 mr-2"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#fff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
            <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
          </svg>
          {CONSTANTS.resultPageResetButtonText}
        </button>
        <button
          type="button"
          className="text-white disabled:bg-blue-900 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          disabled={isLoading}
          onClick={handleClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-file-type-pdf size-6 mr-2"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#fff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
            <path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
            <path d="M17 18h2" />
            <path d="M20 15h-3v6" />
            <path d="M11 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z" />
          </svg>
          {isLoading
            ? "Generating file..."
            : CONSTANTS.resultPageGeneratePdfButtonText}
        </button>
      </div>
      <section className="text-center">
        <div className="text-xl mb-5 font-semibold text-[#717171] dark:text-[#CFCFCF]">
          <p>Thanks for completing the questionaire</p>
        </div>
        <div className="px-12 lg:px-28 mb-6 font-normal text-[#717171] dark:text-[#CFCFCF]">
          <p>
            To obtain the action plan result according to the anwered questions,
            download the PDF document by clicking the 'Download PDF' button
            above.
          </p>
          <br />
          <p>
            In this section bellow you can get more information about the
            answered questions and some resulting graphs about these questions.
          </p>
        </div>
      </section>
      <hr className="border-black border-1 my-4" />
      <div className="mt-2 flex flex-col">
        {charts.map((chartEntry) => {
          return (
            <article key={chartEntry.id} className="px-2 pb-1">
              <h2 className="text-lg font-semibold text-[#717171] dark:text-[#CFCFCF]">
                {chartEntry.questionTitle}
              </h2>
              <div className="relative h-[40vw] w-[80vw] flex justify-center items-center mb-5">
                {chartEntry.chart}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
}

export default ResultComponent;
