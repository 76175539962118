import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';

function App() {
  return (
    <div className="App flex flex-col h-screen">
      <div className="absolute top-0 z-[-2] h-screen w-screen bg-slate-100 dark:bg-[#4C4C55] bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]"></div>
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
