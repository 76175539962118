
/**
 * Footer component for the application.
 *
 * @returns {JSX.Element} The rendered footer element.
 */
function Footer() {
    return (
        <footer
            className="flex flex-col items-center text-center text-surface lg:text-left bg-[#86E1C3] dark:bg-[#6C6C6C] rounded-lg shadow">
            <div className="max-w-[1600px] w-full md:flex md:flex-wrap justify-between items-center text-center px-4">
                <div className="mb-6 md:mb-0">
                    <img src="logos/interreg-logo.svg" alt="Circular WEEEP Logo" className="md:w-48 lg:w-72 h-32" loading='lazy' />
                </div>
                <div className="mb-6 md:mb-0"></div>
            </div>
            <div className="w-full bg-black/5 p-4 text-center">
                <span className="text-sm text-slate-800 dark:text-[#CFCFCF] sm:text-center">© 2024 <a href="https://digitaleus.eu/" className="hover:underline">Digitaleus™</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}

export default Footer;